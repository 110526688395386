<template>
    <div>
		<page-title :heading=heading :subheading=subheading></page-title>
		

		<a-card :bordered="false" class="header-solid px-5 mb-5 pb-1" :bodyStyle="{padding: 0, paddingTop: '16px', marginBottom: '90px !important'}">
			<a-row class="pt-4">
				<a-col :span="12">
					<h5 class="font-semibold">Product Details</h5>
				</a-col>
				<a-col :span="12" class="text-right mb-2">
					<a-button id="txtBtn" type="text" size="default" class="txtBtn mr-3 pt-3" style="color: #FB8C00; font-size: 16px;" @click="onEditDrugItem">
						<a-icon type="form" /> Edit
					</a-button>

					<a-button id="txtBtn" type="text" size="default" class="txtBtn mr-3 pt-3" style="color: #F44335; font-size: 16px;" @click="showDrugDeletionConfirmation">
						<a-icon type="delete" /> Delete
					</a-button>

					<a-button type="primary" :href="`/pharmacy-management/products`" class="">
						<a-icon type="arrow-left" theme="outlined" class="text-white mr-0" />Back
					</a-button>
				</a-col>

				<a-col :span="24">
					<a-divider class="mt-1 pt-0"/>
				</a-col>
			</a-row>
			
			<div class="">
			
				<a-row :gutter="24">
					<a-col :span="24" :md="12" class="pb-4">
						<label><strong>Name</strong></label>
						<a-input 
							disabled
							v-model="medicine.name"
							/>
					</a-col>

					<a-col :span="24" :md="12" class="pb-4">
						<label><strong>Generic Name</strong></label>
						<a-input 
							disabled
							v-model="medicine.genericName"
							/>
					</a-col>
				</a-row>


				<a-row :gutter="24">
					<a-col :span="24" :md="12" class="pb-4">
						<label><strong>Type</strong></label>
						<a-input 
							disabled
							v-model="medicine.type"
							/>
					</a-col>

					<a-col :span="24" :md="12" class="pb-4">
						<label><strong>Storage Temperature</strong></label>
						<a-input 
							disabled
							v-model="medicine.storageTemperature"
							/>
					</a-col>
				</a-row>


				<a-row :gutter="24">
	
					<a-col :span="24" :md="12" class="pb-4">
						<label><strong>Description</strong></label>
						<a-input 
							disabled
							v-model="medicine.description"
							/>
					</a-col>

					<a-col :span="24" :md="12" class="pb-4">
						<label><strong>Quantity Registered(Batches)</strong></label>
						<a-input 
							disabled
							v-model="medicine.quantityRegistered"
							/>
					</a-col>
				</a-row>


				<a-row :gutter="24" class="pb-0">
	
					<a-col :span="24" :md="12" class="pb-4">
						<label><strong>Quatity Sold</strong></label>
						<a-input 
							disabled
							v-model="medicine.quantitySold"
							/>
					</a-col>

					<a-col :span="24" :md="12" class="pb-4">
						<label><strong>Quantity Left</strong></label>
						<a-input 
							disabled
							v-model="medicine.quantityLeft"
							/>
					</a-col>
				</a-row>

				
				<a-row :gutter="24" class="pb-0">
	
					<a-col :span="24" :md="12" class="">
						<label><strong>Total Sales</strong></label>
						<a-input 
							disabled
							v-model="medicine.totalSaleAmount"
							/>
					</a-col>

				</a-row>
				
			</div>
		</a-card>
		
		
		<a-card :bordered="false" class="header-solid px-5 pt-0" :bodyStyle="{marginTop: '80px'}">
			<a-row class="pt-4">
				<a-col :span="12">
					<h5 class="font-semibold">List of Batches</h5>
				</a-col>
				<a-col :span="12" class="text-right mb-2">
					<a-button type="primary" @click="onCreateItem" class="">
						<a-icon type="plus" theme="outlined" class="text-white mr-0" />Add New Batch
					</a-button>
				</a-col>

				<a-col :span="24">
					<a-divider class="mt-1 pt-0"/>
				</a-col>
			</a-row>
			

			<div class="">
				<a-row type="flex" :gutter="24">
					<a-col :span="24" :md="12">
						<a-select v-model="pagination.perPage" :options="pagination.perPageOptions" style="width: 70px"/>
						<label for="" class="ml-10">&nbsp;&nbsp;entries per page</label>
					</a-col>
					<a-col :span="24" :md="12" class="text-right">
                        <a-input-search
                            placeholder="search name..." 
							style="max-width: 250px;" 
							v-model="pagination.search"
                            @search="onSearchItem">
							<template #suffix v-if="pagination.search != null && pagination.search.length > 0">
								<a-icon type="close" theme="outlined" class="text-error mr-3"  @click="onClearSearchItem" />
							</template>
						</a-input-search>
					</a-col>
				</a-row>
			</div>
			
			<div class="mt-5">
				<a-row type="flex" :gutter="24" class="pb-4">
					<a-col :span="24" :md="24">
						<a-table class="mt-20 pb-2"  
							size="middle"
							:responsive="true"
							:bordered="false"
							:columns="columns" 
							:data-source="records" 
							:rowKey="record => record.uuid"
							:loading="pagination.loading" 
							:pagination="false">

								<template slot="s_no" slot-scope="s_no, record, index">
									{{ (pagination.currentPage - 1) * pagination.perPage + index + 1 }}
								</template>

								<template slot="entryDate" slot-scope="entryDate">
									{{ $Moment(entryDate).format("DD MMM YYYY") }}
								</template>

								<template slot="sellingPrice" slot-scope="sellingPrice">
									{{ sellingPrice.toLocaleString() }}
								</template>

								<template slot="totalBuyingPrice" slot-scope="totalBuyingPrice">
									{{ totalBuyingPrice.toLocaleString() }}
								</template>

								<!-- <template slot="manufacturedDate" slot-scope="manufacturedDate">
									{{ $Moment(manufacturedDate).format("DD MMM YYYY") }}
								</template> -->

								<template slot="expiryDate" slot-scope="expiryDate">
									{{ $Moment(expiryDate).format("DD MMM YYYY") }}
								</template>

								<!-- <template slot="createdAt" slot-scope="createdAt">
									{{ $Moment(createdAt).format("DD MMM YYYY") }}
								</template> -->

								<template slot="status" slot-scope="status">
									<a-badge  
										style="font-size: 12px !important; padding: 2px 5px;" 
										:count="`${status[0].toUpperCase()}${status.substring(1)}`" 
										:number-style="status == 'approved' ? { backgroundColor: '#52C41A', color: '#FFFFFF'} : status == 'rejected' ? { backgroundColor: '#F44335', color: '#FFFFFF'} : { backgroundColor: '#D9D9D9', color: '#444', boxShadow: '0 0 0 1px #d9d9d9 inset'}"/>
								</template>

								<template slot="action" slot-scope="action, record">
									<a-button id="txtBtn" type="text" class="pb-0 pt-0 txtBtn text-success" style="" :href="`/pharmacy-management/products/view/${$route.params.uuid}/batch/${record.uuid}`">
										<a-icon type="eye" /> View
									</a-button>
								</template>
							
						</a-table>
					</a-col>
					<a-col :span="12" class="mt-2 text-muted">
						<p class="category">Showing {{from + 1}} to {{to}} of {{pagination.total}} entries</p>
					</a-col>
					<a-col :span="12" class="text-right mt-2">
						<a-pagination 
							v-model="pagination.currentPage" 
							:pageSize="pagination.perPage"  
							:total="pagination.total" 
							@change="onCurrentPageChange"/>
					</a-col>
				</a-row>
			</div>
		</a-card>



		<!--DRUG FORM MODAL -->
		<a-modal v-model="drugItem.showModal" :footer="null">
			<h4 class="text-center">{{ drugItem.isCreate ? 'Add Product' : 'Edit Product'}}</h4>
			<a-form
				:model="drugItem"
                :form="formDrug"
                class="mb-0 pb-0"
                layout="vertical"
				@submit.prevent="handleSubmitDrug">

				<a-form-item 
					class="my-0 mt-4 pb-0" 
					label="Name"
					:colon="false">
						<a-input 
							name="name"
							v-decorator="['name', { rules: [{ required: true, message: 'Please enter the name of the product!' }] }]"
							placeholder="Name"
							/>
				</a-form-item>

				<a-form-item 
					class="my-0 mt-4 pb-0" 
					label="Generic Name"
					:colon="false">
						<a-input 
							name="genericName"
							v-decorator="['genericName', { rules: [{ required: true, message: 'Please the generic name of the product!' }] }]"
							placeholder="Generic Name"
							/>
				</a-form-item>

				<a-form-item 
					class="my-0 mt-4 pb-0" 
					label="Type"
					placeholder="Select Type"
					:colon="false">
					<a-select placeholder="Select Type" 
						name="typeUuid"
						v-decorator="[ 'typeUuid',
						{ rules: [{ required: true, message: 'Please select route of the product!' }] },]">
						<a-select-option v-for="option in medicineTypes"
							:value="option.uuid"
							:key="option.name">
							{{ option.name }}
						</a-select-option>
					</a-select>
				</a-form-item>

				<a-form-item 
					class="my-0 mt-4 pb-0" 
					label="Storage Temperature"
					:colon="false">
						<a-input  
							name="storageTemperature"
							v-decorator="['storageTemperature', { rules: [] }]"
							placeholder="Storage Temperature"/>
				</a-form-item>

				<a-form-item 
					class="my-0 mt-4 pb-0" 
					label="Description"
					:colon="false">
						<a-input 
							name="description"
							v-decorator="['description', { rules: [] }]"
							placeholder="Description"/>
				</a-form-item>

				<a-form-item class="my-0 mt-4 pt-2 pb-2"> 
					<a-button type="primary" color="#12A68B" :loading="drugItem.loading" html-type="submit" class="">
						{{ drugItem.isCreate ? 'Create' : 'Update' }}
					</a-button>
				</a-form-item>

			</a-form>
			
		</a-modal>


		<!-- DELETE DRUG CONFIRMATION -->
		<a-modal v-model="deleteDrugDataItem.showModal" :footer="null" width="416px">

			<a-row :gutters="24" class="">
				<a-col :span="24" :md="3" :lg="3" :xl="3" class="mt-10">
					<a-icon type="warning" theme="outlined" class="text-warning" style="font-size: 25px;"/>
				</a-col>

				<a-col :span="24" :md="21" :lg="21" :xl="21">

					<a-row :gutters="24">
						<a-col :span="24">
							<h6>Do you want to delete this product?</h6>
							<p>Once you delete, you will not be able to recover this inforamtion.</p>
						</a-col>
					</a-row>

					<a-row :gutters="24">
						<a-col :span="24" class="text-right">
							<a-button id="txtBtn" type="text" class="text-secondary txtBtn" @click="cancelDrugItemDeletion">
								Cancel
							</a-button>
							&nbsp;&nbsp;
							<a-button :loading="deleteDrugDataItem.loading" id="txtBtn" type="text" class="text-danger txtBtn" @click="onDeleteDrugItem">
                                Delete
							</a-button>
						</a-col>
					</a-row>
				</a-col>
			</a-row>
      
		</a-modal>


		<!-- FORM MODAL -->
		<a-modal v-model="newItem.showModal" :footer="null">
			<h4 class="text-center">{{ newItem.isCreate ? 'Add Batch' : 'Edit Batch'}}</h4>
			<a-form
				:model="newItem"
                :form="form"
                class="mb-0 pb-0"
                layout="vertical"
				@submit.prevent="handleSubmit">

				<a-form-item 
					class="my-0 mt-4 pb-0" 
					label="Batch Number"
					:colon="false">
						<a-input 
							name="batchNumber"
							v-decorator="['batchNumber', { rules: [{ required: true, message: 'Please enter the batch number!' }] }]"
							placeholder="Batch Number"
							/>
				</a-form-item>

				<a-form-item 
					class="my-0 mt-4 pb-0" 
					label="Entry Date"
					:colon="false">
						<a-date-picker
							format="DD MMM YYYY"
							style="width: 100%"
							v-decorator="['entryDate', { rules: [{ required: true, message: 'Please select the entry date!' }] }]"
							placeholder="Entry Date" />
				</a-form-item>


				<a-form-item 
					class="my-0 mt-4 pb-0" 
					label="Quantity"
					:colon="false">
						<a-input 
							name="quantity"
							type="number"
							v-decorator="['quantity', { rules: [{ required: true, message: 'Please the generic name of the product!' }] }]"
							placeholder="Quantity"
							/>
				</a-form-item>


				<a-form-item 
					class="my-0 mt-4 pb-0" 
					label="Total Buying Price"
					:colon="false">
						<a-input 
							name="totalBuyingPrice"
							type="number"
							v-decorator="['totalBuyingPrice', { rules: [{ required: true, message: 'Please enter the total buying price!' }] }]"
							placeholder="Buying Price"
							/>
				</a-form-item>

				<a-form-item 
					class="my-0 mt-4 pb-0" 
					label="Selling Price (each)"
					:colon="false">
						<a-input 
							name="sellingPrice"
							type="number"
							v-decorator="['sellingPrice', { rules: [{ required: true, message: 'Please enter the selling price!' }] }]"
							placeholder="Selling Price for each item"
							/>
				</a-form-item>

				
				<a-form-item 
					class="my-0 mt-4 pb-0" 
					label="Manufactured Date"
					:colon="false">
						<a-date-picker
							format="DD MMM YYYY"
							style="width: 100%"
							v-decorator="['manufacturedDate', { rules: [{ required: true, message: 'Please select the manufactured date!' }] }]"
							placeholder="manufacturedDate" />
				</a-form-item>

				<a-form-item 
					class="my-0 mt-4 pb-0" 
					label="Expiry Date"
					:colon="false">
						<a-date-picker
							format="DD MMM YYYY"
							style="width: 100%"
							v-decorator="['expiryDate', { rules: [{ required: true, message: 'Please select the expiry date!' }] }]"
							placeholder="expiryDate" />
				</a-form-item>

				<a-form-item class="my-0 mt-4 pt-2 pb-2"> 
					<a-button type="primary" color="#12A68B" :loading="newItem.loading" html-type="submit" class="">
						{{ newItem.isCreate ? 'Create' : 'Update' }}
					</a-button>
				</a-form-item>

			</a-form>
			
		</a-modal>



		<!-- DELETE CONFIRMATION -->
		<a-modal v-model="deleteDataItem.showModal" :footer="null" width="416px">

			<a-row :gutters="24" class="">
				<a-col :span="24" :md="3" :lg="3" :xl="3" class="mt-10">
					<a-icon type="warning" theme="outlined" class="text-warning" style="font-size: 25px;"/>
				</a-col>

				<a-col :span="24" :md="21" :lg="21" :xl="21">

					<a-row :gutters="24">
						<a-col :span="24">
							<h6>Do you want to delete this record?</h6>
							<p>Once you delete, you will not be able to recover this inforamtion.</p>
						</a-col>
					</a-row>

					<a-row :gutters="24">
						<a-col :span="24" class="text-right">
							<a-button id="txtBtn" type="text" class="text-secondary txtBtn" @click="cancelItemDeletion">
								Cancel
							</a-button>
							&nbsp;&nbsp;
							<a-button id="txtBtn" type="text" class="text-danger txtBtn" @click="onDeleteItem">
                                Delete
							</a-button>
						</a-col>
					</a-row>
				</a-col>
			</a-row>
      
		</a-modal>

    </div>
  </template>
  
  <script>
  
    import PageTitle from "../../Layout/Components/PageTitle.vue";

    const columns = [
		{
            title: 'S/N',
            dataIndex: 's_no',
			scopedSlots: { customRender: 's_no' },
			// width: "5%"
        },
		{
			title: 'BATCH',
			dataIndex: 'batchNumber',
			// //width: "15%"
			// sorter: (a, b) => a.name.length - b.name.length,
			// sortDirections: ['descend', 'ascend'],
		},
		{
			title: 'ENTRY DATE',
			dataIndex: 'entryDate',
			scopedSlots: { customRender: 'entryDate' },
			// //width: "15%"
			// sorter: (a, b) => a.name.length - b.name.length,
			// sortDirections: ['descend', 'ascend'],
		},
		{
			title: 'QUANTITY',
			dataIndex: 'quantity',
			// width: "13%"
			// sorter: (a, b) => a.name.length - b.name.length,
			// sortDirections: ['descend', 'ascend'],
		},
		{
			title: 'SELLING PRICE',
			dataIndex: 'sellingPrice',
			scopedSlots: { customRender: 'sellingPrice' },
			// width: "10%"
			// sorter: (a, b) => a.name.length - b.name.length,
			// sortDirections: ['descend', 'ascend'],
		},
		{
			title: 'TOTAL BUYING PRICE',
			dataIndex: 'totalBuyingPrice',
			scopedSlots: { customRender: 'totalBuyingPrice' },
			// width: "10%"
			// sorter: (a, b) => a.name.length - b.name.length,
			// sortDirections: ['descend', 'ascend'],
		},
		// {
		// 	title: 'MANUFACTURED DATE',
		// 	dataIndex: 'manufacturedDate',
		// 	scopedSlots: { customRender: 'manufacturedDate' },
		// 	// //width: "15%"
		// 	// sorter: (a, b) => a.name.length - b.name.length,
		// 	// sortDirections: ['descend', 'ascend'],
		// },
		{
			title: 'EXPIRY DATE',
			dataIndex: 'expiryDate',
			scopedSlots: { customRender: 'expiryDate' },
			// width: "8%"
			// sorter: (a, b) => a.name.length - b.name.length,
			// sortDirections: ['descend', 'ascend'],
		},
		// {
		// 	title: 'STATUS',
		// 	dataIndex: 'status',
		// 	scopedSlots: { customRender: 'status' },
		// 	// width: "8%"
		// 	// sorter: (a, b) => a.name.length - b.name.length,
		// 	// sortDirections: ['descend', 'ascend'],
		// },
		// {
		// 	title: 'CREATED AT',
		// 	dataIndex: 'createdAt',
		// 	scopedSlots: { customRender: 'createdAt' },
		// 	// width: "10%"
		// },
		{
			title: 'ACTION',
			dataIndex: 'action',
			scopedSlots: { customRender: 'action' },
			// width: "30%"
			// align: "center"
		},
	];


    const rules = {
      name: [
        { required: true, message: 'Please input Activity name', trigger: 'blur' },
      ],
    };
  
    export default {
		components: {
			PageTitle,
		},
		data() {
			return {
            heading: 'Settings',
            subheading: 'Medicine Types',
            icon: 'pe-7s-drawer icon-gradient bg-happy-itmeo',
    
            fields: [ 'first_name', 'last_name', 'age' ],
            items: [
            { age: 40, first_name: 'Dickerson', last_name: 'Macdonald' },
            { age: 21, first_name: 'Larsen', last_name: 'Shaw' },
            { age: 89, first_name: 'Geneva', last_name: 'Wilson' }
            ],
            striped: false,
            bordered: false,
            outlined: false,
            small: false,
            hover: false,
            dark: false,
            fixed: false,
            footClone: false,

            pagination: {
                search: '',
                loading: false,
                perPage: 10,
                currentPage: 1,
                perPageOptions: [
                    {label: "10", value: 10}, 
                    {label: "20", value: 20}, 
                    {label: "30", value: 30}, 
                    {label: "50", value: 50}
                ],
                total: 10
            },

            columns,
            message: '',

            records: [],
			medicineRoutes: [],
			medicineTypes: [],
			medicineUnits: [],
            
            form: '',
            rules,

			medicine: {
				uuid: null,
				name: null,
				route: null,
				unit: null,
				type: null,
				description: null,
				genericName: null,
				storageTemperature: null,
				quantityRegistered: null,
				quantitySold: null,
				quantityLeft: null,
				totalSaleAmount: null,
			},

            newItem: {
                uuid: null,
                name: null,
                companyUuid: null,
                codeName: null,
                description: null,
                isCreate: true,
                loading: false,
                showModal: false,
            },


			drugItem: {
				uuid: null,
				name: null,
				companyUuid: null,
				codeName: null,
				description: null,
				isCreate: true,
				loading: false,
				showModal: false,
			},

            deleteDataItem: {
                uuid: null,
                showModal: false,
            },


			deleteDrugDataItem: {
                uuid: null,
                showModal: false,
				loading: false,
            },

            userDetails: {
                user: null,
                company: null
            },

            notify: {
                text: null,
                type: null,
            },
        }
		},
		beforeCreate() {
			// Creates the form and adds to it component's "form" property.
			this.form = this.$form.createForm(this, { name: 'newItemForm' });
			this.formDrug = this.$form.createForm(this, { name: 'newDrugForm' });
		},
      computed: {
            to () {
                let highBound = this.from + this.pagination.perPage
                if (this.total < highBound) {
                    highBound = this.total
                }
                return highBound
            },
            from () {
                return this.pagination.perPage * (this.pagination.currentPage - 1)
            },
        },
		watch: {
            pagination: {
                handler() {
                    this.getPharmacyMedicines();
                },
            },
            deep: true,
        },
		created() {
			this.form = this.$form.createForm(this, { name: 'newItemForm' })
            this.getUserDetails();
			this.getMedicineDetails();
			this.getMedicineTypes();
			this.getPharmacyMedicines();
		},
		methods: {

			onSearchItem() {
				if(this.pagination.search != null && this.pagination.search.length > 0) {
					this.getPharmacyMedicines()
				}else{
					this.notify.text = "Please enter something to search"
					this.notify.type = "error"

					this.$notify(this.notify)
				}
			},

			onClearSearchItem() {
				this.pagination.search = '';

				this.getPharmacyMedicines();
			},

			onCurrentPageChange(value) {
				this.pagination.currentPage = value;

				this.getPharmacyMedicines();
			},

			onPerPageChange(value) {
                this.pagination.perPage = value;

                this.getPharmacyMedicines();
            },


            async getUserDetails() {
                let userDetails = await JSON.parse(localStorage.getItem("pharmUser"));

                if(userDetails != null) {
                    this.userDetails.user = userDetails.user;
                    this.userDetails.company = userDetails.company;
                }else {
                    this.$router.push(`/`);
                }
            },


			async getMedicineDetails() {
                
                let url = `${this.$BACKEND_URL}/reports/drugs/show/${this.$route.params.uuid}`;

                this.$AXIOS.get(url).then((response) => {
                    if (response.status == 200) {
						this.medicine.uuid = response.data.uuid;
						this.medicine.name = response.data.name;
						this.medicine.genericName = response.data.genericName;
						this.medicine.type = response.data.type;
						this.medicine.typeUuid = response.data.typeUuid;
						this.medicine.quantitySold = response.data.quantitySold;
						this.medicine.quantityRegistered = response.data.quantityRegistered;
						this.medicine.totalSaleAmount = response.data.totalSaleAmount;
						this.medicine.quantityLeft = response.data.quantityRegistered != null && response.data.quantitySold != null ? 
							parseInt(response.data.quantityRegistered) - parseInt(response.data.quantitySold) : response.data.quantityRegistered != null ? 
							parseInt(response.data.quantityRegistered) : null,
						this.medicine.description = response.data.description;
						this.medicine.storageTemperature = response.data.storageTemperature;
                    }
					
                }).catch((error) => {
                    if(error.response != null && error.response.status == 401) {
                        this.$router.push(`/`);
                    }

                    // eslint-disable-next-line no-console
                    // console.log(error)

                    this.notify.text = error.response != null &&  error.response.data != null ? error.response.data.message : "Something went wrong, please try again!";
                    this.notify.type = "error"

                    this.$notify(this.notify)

                });
            },

			async getPharmacyMedicines() {
                this.pagination.loading = true;

                const { currentPage, perPage, search } = this.pagination;

                let url = `${this.$BACKEND_URL}/pharmacy-drugs/drug/${this.$route.params.uuid}?search=${search}&page=${currentPage}&perPage=${perPage}`;

                this.$AXIOS.get(url).then((response) => {
                    if (response.status == 200) {
                        if(response.data.data.length > 0) {
                            this.records = response.data.data;
                            this.pagination.totalPages = response.data.totalPages;
                            this.pagination.total = response.data.total;
                            this.pagination.currentPage = response.data. currentPage;
                        }else{
                            this.notify.text = "There is no data available";
							this.notify.type = "success";
                            this.$notify(this.notify);
                        }
                    }
                    this.pagination.loading = false;
                }).catch((error) => {
                    if(error.response != null && error.response.status == 401) {
                        this.$router.push(`/`);
                    }

                    // eslint-disable-next-line no-console
                    // console.log(error)

                    this.notify.text = error.response != null &&  error.response.data != null ? error.response.data.message : "Something went wrong, please try again!";
                    this.notify.type = "error"

                    this.$notify(this.notify)

                    this.pagination.loading = false;
                });
            },


			async getMedicineTypes() {

                const currentPage = 1;
				const perPage = 1000;

				let userDetails = await JSON.parse(localStorage.getItem("pharmUser"));

                let companyUuid = userDetails.company != null ? userDetails.company.uuid : null

                let url = `${this.$BACKEND_URL}/drug-types/${companyUuid}?page=${currentPage}&perPage=${perPage}`;

                this.$AXIOS.get(url).then((response) => {
                    if (response.status == 200) {
                        this.medicineTypes = response.data.data;
                    }
					
                }).catch((error) => {
					
                    this.notify.text = error.response &&  error.response.data ? error.response.data.message : "Something went wrong, try again please!";
                    this.notify.type = "danger"
                    this.$notify(this.notify)

                });
            },


			async onEditDrugItem() {
				// this.form = this.$form.createForm(this, { name: 'newItemForm' })
				this.drugItem.isCreate = false;
				this.drugItem.showModal = true;
                this.drugItem.uuid = this.$route.params.uuid;
                this.drugItem.companyUuid = this.userDetails.company.uuid;
				this.drugItem.loading = false;
				// eslint-disable-next-line no-console
				setTimeout(() => {
					this.formDrug.setFieldsValue({
						name: this.medicine.name,
						genericName: this.medicine.genericName,
						typeUuid: this.medicine.typeUuid,
						// routeUuid: this.medicine.route != null ? this.medicine.route.uuid : null,
						// unitUuid: this.medicine.unit != null ? this.medicine.unit.uuid : null,
						storageTemperature: this.medicine.storageTemperature,
						description: this.medicine.description,
					});
				}, 10);

            },



			async handleSubmitDrug() {

				this.formDrug.validateFields((err, values) => {
					if ( !err ) {

						this.drugItem.loading = true;

						// eslint-disable-next-line no-console
			
						let url = `${this.$BACKEND_URL}/drugs/${this.$route.params.uuid}`

						let payload = values;

						this.$AXIOS.patch(url, payload).then(response => {

							if (response.status >= 200 && response.status < 210) {

								this.formDrug.resetFields()

								this.drugItem.uuid = null;
								this.drugItem.name = null;
								this.drugItem.codeName = null;
								this.drugItem.description = null;
								this.drugItem.isCreate = true;
								this.drugItem.loading = false;
								this.drugItem.showModal = false;

								this.notify.text = response.data.message
								this.notify.type = "success"

								this.$notify(this.notify)

							}

							this.getMedicineDetails()
			
						}).catch(error => {
						
							this.drugItem.loading = false;
				
							if(error.response != null && error.response.status == 401) {
								this.$router.push(`/`);
							}

							this.notify.text = error.response != null &&  error.response.data != null ? error.response.data.message : "Something went wrong, please try again!";
							this.notify.type = "error"

							this.$notify(this.notify)
						});

					}
					
				})
				
			},


			showDrugDeletionConfirmation(uuid) {
				this.deleteDrugDataItem.uuid = uuid;
				this.deleteDrugDataItem.showModal = true;
			},

			cancelDrugItemDeletion() {
				this.deleteDrugDataItem.uuid = null;
				this.deleteDrugDataItem.showModal = false;
			},

			onDeleteDrugItem() {

				this.deleteDrugDataItem.loading = true;

                let url = `${this.$BACKEND_URL}/drugs/${this.$route.params.uuid}`;

                this.$AXIOS.delete(url).then((response) => {
                        
					this.deleteDrugDataItem.uuid = null;
					this.deleteDrugDataItem.showModal = false;
                
                    this.deleteDrugDataItem.loading = false;

					this.notify.text = response.data.message;
                    this.notify.type = "success"

                    this.$notify(this.notify)

					this.$router.push("/pharmacy-management/drugs")

                }).catch((error) => {
                    if(error.response != null && error.response.status == 401) {
                        this.$router.push(`/`);
                    }

                    this.notify.text = error.response != null &&  error.response.data != null ? error.response.data.message : "Something went wrong, please try again!";
                    this.notify.type = "error"

                    this.$notify(this.notify)

                    this.deleteDrugDataItem.loading = false;
                });
			},



			onCreateItem() {
                // this.newItem.uuid = null;
                this.newItem.companyUuid = this.userDetails.company.uuid;
				// this.newItem.name = null;
				// this.newItem.codeName = null;
				// this.newItem.description = null;
				this.newItem.isCreate = true;
				this.newItem.loading = false;
				this.newItem.showModal = true;
            },

			async onEditItem(item) {
				// this.form = this.$form.createForm(this, { name: 'newItemForm' })
				this.newItem.isCreate = false;
				this.newItem.showModal = true;
                this.newItem.uuid = item.uuid;
                this.newItem.companyUuid = this.userDetails.company.uuid;
				this.newItem.loading = false;
				// eslint-disable-next-line no-console
				// console.log(item)
				setTimeout(() => {
					this.form.setFieldsValue({
						batchNumber: item.batchNumber,
						entryDate: item.entryDate,
						quantity: item.quantity,
						sellingPrice: item.sellingPrice,
						totalBuyingPrice: item.totalBuyingPrice,
						manufacturedDate: item.manufacturedDate,
						expiryDate: item.expiryDate,
					});
				}, 10);

            },


			async handleSubmit() {

				this.form.validateFields((err, values) => {
					if ( !err ) {

						if(this.newItem.isCreate) {

							this.newItem.loading = true;
				
							let url = `${this.$BACKEND_URL}/pharmacy-drugs`
							
							let payload = values;

							payload.entryDate = this.$Moment(values.entryDate._d).format("YYYY-MM-DD")
							payload.manufacturedDate = this.$Moment(values.manufacturedDate._d).format("YYYY-MM-DD")
							payload.expiryDate = this.$Moment(values.expiryDate._d).format("YYYY-MM-DD")

							payload.companyUuid = this.userDetails.company.uuid;
							payload.drugUuid = this.$route.params.uuid;

							this.$AXIOS.post(url, payload).then(response => {

								if (response.status >= 200 && response.status < 210) {

									this.form.resetFields()

									this.newItem.uuid = null;
									this.newItem.name = null;
									this.newItem.codeName = null;
									this.newItem.description = null;
									this.newItem.isCreate = true;
									this.newItem.loading = false;
									this.newItem.showModal = false;

									this.notify.text = response.data.message
									this.notify.type = "success"

									this.$notify(this.notify)

								}

								this.getPharmacyMedicines()

							}).catch(error => {
							
								this.newItem.loading = false;
					
								if(error.response != null && error.response.status == 401) {
									this.$router.push(`/`);
								}

								this.notify.text = error.response != null &&  error.response.data != null ? error.response.data.message : "Something went wrong, please try again!";
								this.notify.type = "error"

								this.$notify(this.notify)
					
							});

						}else{

							this.newItem.loading = true;

							// eslint-disable-next-line no-console
							console.log(this.form)
				
							let url = `${this.$BACKEND_URL}/pharmacy-drugs/${this.newItem.uuid}`

							let payload = values;

							payload.entryDate = this.$Moment(values.entryDate._d).format("YYYY-MM-DD")
							payload.manufacturedDate = this.$Moment(values.manufacturedDate._d).format("YYYY-MM-DD")
							payload.expiryDate = this.$Moment(values.expiryDate._d).format("YYYY-MM-DD")

							this.$AXIOS.patch(url, payload).then(response => {

								if (response.status >= 200 && response.status < 210) {

									this.form.resetFields()

									this.newItem.uuid = null;
									this.newItem.name = null;
									this.newItem.codeName = null;
									this.newItem.description = null;
									this.newItem.isCreate = true;
									this.newItem.loading = false;
									this.newItem.showModal = false;

									this.notify.text = response.data.message
									this.notify.type = "success"

									this.$notify(this.notify)

								}

								this.getPharmacyMedicines()
				
							}).catch(error => {
							
								this.newItem.loading = false;
					
								if(error.response != null && error.response.status == 401) {
									this.$router.push(`/`);
								}

								this.notify.text = error.response != null &&  error.response.data != null ? error.response.data.message : "Something went wrong, please try again!";
								this.notify.type = "error"

								this.$notify(this.notify)
							});
						}

					}
				})
				
			},


			showDeletionConfirmation(uuid) {
				this.deleteDataItem.uuid = uuid;
				this.deleteDataItem.showModal = true;
			},

			cancelItemDeletion() {
				this.deleteDataItem.uuid = null;
				this.deleteDataItem.showModal = false;
			},

			onDeleteItem() {

				this.deleteDataItem.loading = true;

                let url = `${this.$BACKEND_URL}/drugs/${this.deleteDataItem.uuid}`;

                this.$AXIOS.delete(url).then((response) => {
                        
					this.deleteDataItem.uuid = null;
					this.deleteDataItem.showModal = false;
                
                    this.deleteDataItem.loading = false;

					this.notify.text = response.data.message;
                    this.notify.type = "success"

                    this.$notify(this.notify)

					this.getPharmacyMedicines();

                }).catch((error) => {
                    if(error.response != null && error.response.status == 401) {
                        this.$router.push(`/`);
                    }

                    this.notify.text = error.response != null &&  error.response.data != null ? error.response.data.message : "Something went wrong, please try again!";
                    this.notify.type = "error"

                    this.$notify(this.notify)

                    this.pagination.loading = false;
                });
			},


		},
    }
  </script>
  
  <style lang="scss">
  
  #textBtn, #textBtn:hover {
      border: none !important;
  }
</style>